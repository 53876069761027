import * as React from 'react';
import InfoCard from 'styleguide/components/InfoCards/InfoCard/InfoCard';
import { ServiceCard } from 'contexts/AppContext/types';

interface Props {
  serviceCards: ServiceCard[];
}

const ServiceCardsRow = ({ serviceCards }: Props) => (
  <div className="grid gap-x-6 gap-y-8 md:grid-cols-2">
    {serviceCards.map(card => (
      <InfoCard
        title={card.title}
        content={card.content}
        image={card.imageUrl}
        cardClassNames={{
          cardClasses: '!items-start',
          titleClasses: 'paragraph-bold-desktop text-left text-white',
          imageClasses: '!w-[52px] !h-[52px]',
          contentWrapperClasses: '!w-full !mx-0 md:!max-w-full',
          contentClasses: 'paragraph-medium-desktop text-left text-gray-50',
          subContentClasses: 'paragraph-medium-desktop text-left text-gray-50',
        }}
      />
    ))}
  </div>
);

export default ServiceCardsRow;
