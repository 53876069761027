import ProductCard from 'bundles/App/pages/ProductListingPage/MainSection/ProductCard/ProductCard';
import * as React from 'react';
import { ProductCards } from 'contexts/AppContext/types';

interface Props {
  productCards: ProductCards;
}

const ProductCardsRow = ({ productCards: { productBullets } }: Props) => (
  <div
    className="mx-auto mb-14 grid max-w-screen-2xl justify-items-center gap-y-11 gap-x-12 
    sm:grid-cols-2 sm:px-5 md:grid-cols-3 lg:grid-cols-4"
  >
    {productBullets.map((bulletList, i) => (
      <ProductCard
        title={bulletList.title}
        actionLinkText={bulletList.linkText}
        actionLinkOverride={bulletList.linkUrl}
        imageLinkOverride={bulletList.imageUrl}
        subHeading={bulletList.subtext}
        listItems={[bulletList.bullet1, bulletList.bullet2, bulletList.bullet3]}
        constrainWidth
        key={`product-card-${i}`}
      />
    ))}
  </div>
);

export default ProductCardsRow;
