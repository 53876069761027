import * as React from 'react';
import Image from 'styleguide/components/Image/Image';
import A from 'styleguide/components/Links/A';
import { IconArrowRight } from 'styleguide/icons';
import { ImageBlock, InfoBlock } from 'contexts/AppContext/types';

interface Props {
  infoBlock: InfoBlock;
}

interface ArticleCardProps {
  article: ImageBlock;
}

const ArticleCard = ({
  article: { actionLink, actionText, content, imagePath, title },
}: ArticleCardProps) => (
  <div className="flex min-h-[360px] w-[300px] flex-col justify-between rounded shadow">
    <div className="p-4">
      <Image url={imagePath} alt={title} height={140} width={268} />
    </div>
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-col p-4">
        <div className="paragraph-bold-desktop mb-5">{title}</div>
        <div className="paragraph-desktop overflow-hidden">{content}</div>
      </div>
      <div className="flex items-center justify-between bg-neutral-50 p-4">
        <A
          href={actionLink}
          underline="none"
          color="black"
          className="!font-hvBold !text-xs !font-bold !leading-3"
        >
          {actionText}
        </A>
        <A href={actionLink} color="black" underline="none">
          <IconArrowRight className="!h-3 cursor-pointer" />
        </A>
      </div>
    </div>
  </div>
);

const Articles = ({ infoBlock: { content, content2, imageBlocks, title } }: Props) => (
  <div
    id="articles-body"
    className="items-center px-6 py-16 flex max-w-base mx-auto flex-col xl:flex-row justify-center"
  >
    <div className="flex flex-col -sm:max-w-[300px] space-y-2 xl:space-y-4 md:max-w-2xl lg:max-w-4xl xl:px-0 xl:self-start mb-4 xl:mb-0">
      <h6 className="font-hvBold text-lg/6 sm:text-2xl md:text-3xl lg:text-4xl xl:text-3xl">{title}</h6>
      <div className="font-hvLite text-sm md:text-base leading-4">{content}</div>
      <div className="font-hvLite text-sm md:text-base leading-4">{content2}</div>
    </div>
    <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-center lg:justify-normal lg:flex-nowrap md:space-x-6 -sm:space-y-4">
      {imageBlocks.map((article: ImageBlock) => (
        <ArticleCard article={article} />
      ))}
    </div>
  </div>
);

export default Articles;
