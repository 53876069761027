import * as React from 'react';
import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

type Direction = 'left' | 'right';

interface MegaphoneProps extends SvgProps {
  direction?: Direction;
}

const IconMegaphone = ({
  title = 'Megaphone',
  color = 'dark',
  viewBoxWidth = 25,
  viewBoxHeight = 25,
  direction = 'left',
  ...otherProps
}: MegaphoneProps) => (
  <SvgIcon
    {...{ title, viewBoxWidth, viewBoxHeight, color, direction, ...otherProps }}
    className={otherProps.className}
  >
    <g transform={direction === 'right' ? `scale(-1, 1) translate(-${viewBoxWidth}, 0)` : ''}>
      <polygon fill={colors[color]} points="20.9,8.7 4.1,3.5 4.1,19.5 18.1,14.8 20.9,13.9" />
      <path fill={colors[color]} d="M25,9.2c0,0-1.4-2.5-3.1,0v3.7c0,0,1.6,2.6,3.1,0V9.2z" />
      <path fill={colors[color]} d="M3.1,3.2c0,0-1.5-2.4-3.1,0l0,16.1l0,0.8c0,0,1.4,2.3,3.1,0V3.2z" />
      <path
        fill={colors[color]}
        d="M18.1,14.8l-0.9,0.3c0,0,2,2.5-2.8,3.2c0,0-1.2,0-1.5-1.7L11.8,17c0,0,0.8,2.2,2.4,2.2s4.4-1,4.5-2.2
	S18.1,14.8,18.1,14.8z"
      />
    </g>
  </SvgIcon>
);

export default IconMegaphone;
