import * as React from 'react';
import cn from 'classnames';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import Button from 'styleguide/components/Button/Button';

interface Props {
  title: string;
  content?: string;
  backgroundImage?: string;
  buttonText?: string;
  buttonLink?: string;
  buttonScrollTarget?: string;
  className?: string;
  onClick?: (target: string) => void;
}

const Hero = ({
  title,
  backgroundImage,
  buttonText,
  buttonLink,
  buttonScrollTarget,
  content,
  onClick,
  ...props
}: Props) => {
  const bgImageStyle: string = backgroundImage
    ? `linear-gradient(to right, rgba(0, 0, 0, 0.75) 15%, rgba(0, 0, 0, 0.15) 85.94%), url(${backgroundImage})`
    : null;

  return (
    <div
      className={cn(
        'flex justify-center border-b border-solid border-neutral-300 px-4 text-white',
        bgImageStyle ? 'h-96 md:h-128' : 'bg-gradient-to-r from-[#005778] to-[#48C2EF]',
        props.className,
      )}
      style={{
        backgroundImage: bgImageStyle,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div
        className="flex h-full w-full max-w-base flex-col-reverse items-stretch overflow-hidden
md:flex-row md:justify-center lg:relative lg:max-w-base my-4
lg:pl-0.5"
      >
        <div
          className="mx-auto flex h-full w-full
  flex-col justify-center py-4 text-center  md:py-0 md:text-left"
        >
          <div className="px-1 md:px-2 md:py-4 md:pl-6 md:pr-0 lg:pl-0">
            <h1 className="heading-medium-sm md:heading-medium-md -md:px-20">
              <HtmlContent content={title} />
            </h1>
            <p className="paragraph-medium-mobile md:paragraph-medium-desktop mt-3 mb-6 max-w-md md:mt-4 -md:mx-auto -md:mb-10 -md:p-5">
              {!!content && content}
            </p>
            {!!buttonText && (
              <div className="flex justify-center md:justify-start">
                {buttonLink ? (
                  <ButtonAsNativeLink
                    buttonType="link"
                    className="!mt-0 rounded-md !px-14 shadow md:!py-2 md:!text-base"
                    color="blue"
                    textStyle="bold"
                    target={buttonLink}
                  >
                    {buttonText}
                  </ButtonAsNativeLink>
                ) : (
                  <Button
                    className="!mt-0 rounded-md !px-14 shadow md:!py-2 md:!text-base"
                    color="blue"
                    type="button"
                    onClick={() => onClick(buttonScrollTarget)}
                  >
                    {buttonText}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
