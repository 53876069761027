import AppContext from 'contexts/AppContext/AppContext';
import { CmsPage } from 'contexts/AppContext/types';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';

const useCms = <T extends CmsPage = CmsPage>() => {
  const appContext = useContext(AppContext);
  const { fullPath } = useParams<{ fullPath: string }>();
  const [cms, setCms] = React.useState<T | undefined>(
    appContext.store.cmsPages.find(elem => elem.fullPath === fullPath) as T | undefined,
  );

  useEffect(() => {
    const newCms = appContext.store.cmsPages.find(elem => elem.fullPath === fullPath) as T | undefined;
    setCms(newCms);
  }, [fullPath]);

  return cms;
};

export default useCms;
