import { buildUrl } from 'libs/utils/api';
import apiCall from 'libs/utils/api/apiCall';
import { BasicResponse } from 'api/types';
import { SharedErrors } from 'libs/utils/api/types';

// GET /cms_pages/:id/track
export const trackCmsPage = (id: number) => {
  const url = buildUrl(`/cms_pages/${id}/track`);
  apiCall.get<BasicResponse, SharedErrors>({ url }).then();
};

// GET /cms_pages/:id/track_product
export const trackProductChange = (id: number) => {
  const url = buildUrl(`/cms_pages/${id}/track_product`);
  apiCall.get<BasicResponse, SharedErrors>({ url }).then();
};

// POST /cms_pages/:id/track_clicked_template_link
export const trackClickedTemplateLink = (id: number, templateName: string) => {
  const url = buildUrl(`/cms_pages/${id}/track_clicked_template_link`);
  apiCall.post<BasicResponse, SharedErrors>({ url, data: { templateName } }).then();
};
