import * as React from 'react';
import cn from 'classnames';
import { CallToAction, ServiceCards } from 'contexts/AppContext/types';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import ServiceCardsRow from 'bundles/App/pages/CategoryPage/row/ServiceCardsRow';

interface Props {
  callToAction: CallToAction;
  serviceCardsRow?: ServiceCards;
}

const CallToActionRow = ({ callToAction, serviceCardsRow }: Props) => (
  <div className="flex items-center justify-center px-6 -md:block -md:px-0 w-full">
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0.77) 85.94%), url(${callToAction.backgroundImageUrl})`,
      }}
      className={cn(
        'relative z-10 bg-center grid max-w-screen-2xl gap-4 rounded-lg bg-cover bg-no-repeat p-8 md:grid-cols-2 -md:rounded-none w-full min-h-[400px]',
      )}
    >
      <div className="grid">
        <p className="paragraph-medium-desktop m-0 max-w-sm text-white -md:hidden -md:leading-5">
          {callToAction.subtextLine2}
        </p>
        <div className="justify-center self-end">
          <div className="heading-medium-md -md:heading-medium-sm max-w-md !text-white">
            {callToAction.title}
          </div>
          <p className="paragraph-light-desktop -md:paragraph-light-mobile mt-2 max-w-sm text-white">
            {callToAction.subtextLine1}
          </p>
        </div>
      </div>
      {!!serviceCardsRow && <ServiceCardsRow serviceCards={serviceCardsRow.serviceCards} />}
      <p className="m-0 max-w-sm text-white md:hidden">{callToAction.subtextLine2}</p>
      {!!callToAction.buttonLink && !!callToAction.buttonText && (
        <div className="flex justify-start my-4 md:my-0">
          <ButtonAsNativeLink
            className="!px-16"
            buttonType="link"
            target={callToAction.buttonLink}
            color="orange"
          >
            {callToAction.buttonText}
          </ButtonAsNativeLink>
        </div>
      )}
    </div>
  </div>
);

export default CallToActionRow;
