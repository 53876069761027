import * as React from 'react';
import cn from 'classnames';
import Image from 'styleguide/components/Image/Image';
import Link from 'styleguide/components/Links/Link';
import A from 'styleguide/components/Links/A';
import Span from 'styleguide/components/Span/Span';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import { Info } from './types';

const InfoCard = ({
  iconSize = 'sm',
  title,
  content,
  subcontent,
  image,
  grid,
  cardClassNames,
  alignment,
  ...props
}: Info) => {
  const Icon = props.icon;
  const iconSizeStyle = {
    xxs: '!h-4 !w-4',
    xs: '!h-8 !w-8',
    sm: '!h-12 !w-12',
    md: '!h-20 !w-20',
    lg: '!h-24 !w-24',
    xl: '!h-32 !w-32',
    xxl: '!h-40 !w-40',
  };
  return (
    <div
      key={`${title}`}
      className={cn(
        'flex flex-col justify-start space-y-4 overflow-hidden text-default xl:max-w-[292px]',
        grid ? '!items-start !text-left -md:!items-center -md:!text-center' : '',
        alignment === 'left' ? 'items-left text-left' : 'items-center text-center',
        cardClassNames?.cardClasses,
      )}
    >
      {!!props.icon && (
        <div className="flex-shrink-0">
          <Icon className={cn('text-blue', iconSizeStyle[iconSize])} />
        </div>
      )}
      {!!image && (
        <Image
          title={title}
          alt={title}
          url={image}
          className={cn(iconSizeStyle[iconSize], cardClassNames?.imageClasses)}
        />
      )}
      <div className={cn('font-hvBold', cardClassNames?.titleClasses)}>{title}</div>
      <div
        className={cn(
          'max-w-[280px] text-sm xs:w-1/2 sm:w-full lg:text-base',
          alignment === 'center' && 'mx-auto',
          cardClassNames?.contentWrapperClasses,
        )}
      >
        {!!content && <HtmlContent content={content} className={cardClassNames?.contentClasses} />}
        {!!subcontent && <HtmlContent content={subcontent} className={cardClassNames?.subContentClasses} />}
      </div>
      {!!props.actionLinkText && (
        <Span className={cardClassNames?.linkClasses}>
          {props.useRouterLink ? (
            <Link to={props.actionLink} color="blue" underline="always">
              {props.actionLinkText}
            </Link>
          ) : (
            <A color="blue" underline="always" href={props.actionLink}>
              {props.actionLinkText}
            </A>
          )}
        </Span>
      )}
    </div>
  );
};

export default InfoCard;
